import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
const InfoArea = setComponentTemplate(precompileTemplate(`
  <div class={{@class}} ...attributes data-test-boxel-action-chin-info-area>
    {{yield}}
  </div>
`, {
  strictMode: true
}), templateOnly("info-area", "InfoArea"));
export default InfoArea;