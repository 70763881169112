import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import '@cardstack/boxel/styles/global.css';
import './index.css';
const SidebarSection = setComponentTemplate(precompileTemplate(`
  <section class="boxel-sidebar-section" ...attributes>
    {{#if @title}}
      <h3 class="boxel-sidebar-section__title">
        {{@title}}
      </h3>
    {{/if}}

    <div class="boxel-sidebar-section__content">
      {{yield}}
    </div>
  </section>
`, {
  strictMode: true
}), templateOnly("index", "SidebarSection"));
export default SidebarSection;