function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { helper } from '@ember/component/helper';
export class MenuDivider {
  constructor() {
    _defineProperty(this, "type", void 0);

    this.type = 'divider';
  }

}
export default helper(function () {
  return new MenuDivider();
});