var _class, _descriptor, _descriptor2, _descriptor3;

function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import BoxelIconButton from '../icon-button';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import fetch from 'fetch';
import { assert } from '@ember/debug';
import { htmlSafe } from '@ember/template';
import { on } from '@ember/modifier';
import mediaDuration from '@cardstack/boxel/helpers/media-duration';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import perform from 'ember-concurrency/helpers/perform';
import '@cardstack/boxel/styles/global.css';
import './index.css';
const AudioContext = window.AudioContext || window.webkitAudioContext;
;
let WavePlayerComponent = setComponentTemplate(precompileTemplate(`
    <div class="boxel-wave-player" ...attributes>
      <BoxelIconButton
        onclick={{perform this.playPause}}
        class="boxel-wave-player__play {{if this.isPlaying "boxel-wave-player--is-playing"}}"
        aria-label={{if this.isPlaying "Pause" "Play"}}
        @icon={{if this.isPlaying "pause-btn" "play-btn"}}
        @width="30px"
        @height="30px"
      />
      <canvas
        {{didInsert this.setupPlayer}}
        role="button"
        aria-label="Audio track"
        width={{this.width}} height={{this.height}} style={{htmlSafe this.style}}
        {{on 'click' this.canvasClick}}
      >
      </canvas>

      <div class="boxel-wave-player__times">
        {{mediaDuration this.currentTime}} / {{mediaDuration this.duration}}
      </div>
    </div>
  `, {
  strictMode: true,
  scope: () => ({
    BoxelIconButton,
    perform,
    htmlSafe,
    didInsert,
    on,
    mediaDuration
  })
}), (_class = class WavePlayerComponent extends Component {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "width", 600);

    _defineProperty(this, "height", 152);

    _defineProperty(this, "barWidth", 5);

    _defineProperty(this, "barPadding", 2);

    _defineProperty(this, "audio", void 0);

    _defineProperty(this, "canvas", void 0);

    _defineProperty(this, "sampleRatio", 0.1);

    _defineProperty(this, "unplayedColor", '#D8D8D8');

    _defineProperty(this, "playedColor", '#00EBE5');

    _defineProperty(this, "barValues", []);

    _initializerDefineProperty(this, "currentTime", _descriptor, this);

    _initializerDefineProperty(this, "isPlaying", _descriptor2, this);

    _initializerDefineProperty(this, "duration", _descriptor3, this);
  }

  get style() {
    return `
    width: ${this.width / 2}px;
    height: ${this.height / 2}px;
    `;
  }

  willDestroy() {
    super.willDestroy();

    if (this.isPlaying) {
      this.audio?.pause();
      this.isPlaying = false;
    }
  }

  async setupPlayer(canvas) {
    this.canvas = canvas;
    let {
      url
    } = this.args;
    let response = await fetch(url);
    let buffer = await response.arrayBuffer();
    let audioContext = new AudioContext();
    let decodedData = await decodeAudioData(audioContext, buffer);
    let data = decodedData.getChannelData(0);
    let barCount = Math.floor(this.canvas.width / (this.barWidth + this.barPadding));
    let scaler = makeScaler(barCount, data.length);
    this.barValues = [];

    for (let x of range(barCount)) {
      let startIdx = Math.floor(scaler(x)),
          endIdx = Math.ceil(scaler(x + 1));
      let slice = data.slice(startIdx, endIdx);
      let samplesToTake = Math.floor(this.sampleRatio * slice.length);
      let sum = 0;

      for (let i of range(samplesToTake)) {
        sum += Math.pow(slice[i / this.sampleRatio], 2);
      }

      let rms = Math.sqrt(sum / samplesToTake);
      this.barValues.push(rms);
    }

    this.draw();
    this.setupAudio(url);
  }

  draw() {
    assert('canvas is defined', !!this.canvas);
    let context = this.canvas.getContext('2d');
    assert('context is defined', !!context);
    context.save();
    context.fillStyle = '#fff';
    context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    context.lineWidth = this.barWidth;
    context.translate(0, this.canvas.height / 2);

    for (let i = 0; i < this.barValues.length; i++) {
      let fractionThroughBars = i / this.barValues.length;

      if (fractionThroughBars > this.fractionThroughTrack) {
        context.strokeStyle = this.unplayedColor;
      } else {
        context.strokeStyle = this.playedColor;
      }

      let x = Math.floor(this.canvas.width * i / this.barValues.length);
      let y = this.barValues[i] * this.canvas.height;
      context.beginPath();
      context.moveTo(x, -y);
      context.lineTo(x, y);
      context.stroke();
    }

    context.restore();
  }

  get fractionThroughTrack() {
    if (!this.audio || !this.audio.duration) {
      return 0;
    }

    return this.currentTime / this.audio.duration;
  }

  setupAudio(url) {
    this.audio = new Audio(url);
    assert('audio is present', !!this.audio);
    this.audio.addEventListener('timeupdate', () => {
      assert('audio is present', !!this.audio);
      this.currentTime = this.audio.currentTime;
      this.draw();
    });
    this.audio.addEventListener('play', () => this.isPlaying = true);
    this.audio.addEventListener('pause', () => this.isPlaying = false);
    this.audio.addEventListener('durationchange', () => {
      assert('audio is present', !!this.audio);
      this.duration = this.audio.duration;
    });
  }

  *playPause() {
    if (this.isPlaying) {
      this.audio?.pause();
    } else {
      yield this.audio?.play();
    }
  }

  canvasClick(event) {
    let rect = event.target.getBoundingClientRect();
    let x = event.clientX - rect.left;
    let clickFraction = x / (rect.right - rect.left);
    assert('audio is present', !!this.audio);
    this.audio.currentTime = clickFraction * this.audio.duration;
  }

}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentTime", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 0;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isPlaying", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "duration", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 0;
  }
}), _applyDecoratedDescriptor(_class.prototype, "setupPlayer", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setupPlayer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "playPause", [dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "playPause"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canvasClick", [action], Object.getOwnPropertyDescriptor(_class.prototype, "canvasClick"), _class.prototype)), _class));
export { WavePlayerComponent as default };

function range(n) {
  return [...Array(n).keys()];
}

function makeScaler(domain, range) {
  return x => range / domain * x;
}

async function decodeAudioData(audioContext, buffer) {
  return await new Promise(resolve => audioContext.decodeAudioData(buffer, resolve));
}