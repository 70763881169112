import { helper } from '@ember/component/helper';
export function lt([left, right], hash) {
  if (hash.forceNumber) {
    if (typeof left !== 'number') {
      left = Number(left);
    }

    if (typeof right !== 'number') {
      right = Number(right);
    }
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any


  return left < right;
}
export default helper(lt);