var _dec, _dec2, _class, _descriptor, _descriptor2;

function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import { reads } from 'macro-decorators';
import '@cardstack/boxel/styles/global.css';
import './index.css';
import cn from '@cardstack/boxel/helpers/cn';
import cssVar from '@cardstack/boxel/helpers/css-var';
import BoxelParticipant from '../participant';
import eq from 'ember-truth-helpers/helpers/eq';
import lt from 'ember-truth-helpers/helpers/lt';
import or from 'ember-truth-helpers/helpers/or';
let ParticipantList = setComponentTemplate(precompileTemplate(`
    <ul
      class={{cn
        "boxel-participant-list"
        boxel-participant-list__fanned=@fanned
        boxel-participant-list--full-width=@fullWidth
      }}
      style={{cssVar icon-size=this.iconSize}}
      ...attributes
    >
      {{#each @participants as |participant i|}}
        {{#if (lt i this.maxCount)}}
          <li>
            <BoxelParticipant
              class="boxel-participant-list__participant"
              @title={{participant.title}}
              @description={{participant.role}}
              @image={{participant.imgURL}}
              @hasLogo={{or @hasLogo (eq participant.type "organization")}}
              @iconSize={{this.iconSize}}
              @iconOnly={{or @iconOnly @fanned}}
            />
          </li>
        {{/if}}
      {{/each}}
    </ul>
  `, {
  strictMode: true,
  scope: () => ({
    cn,
    cssVar,
    lt,
    BoxelParticipant,
    or,
    eq
  })
}), (_dec = reads('args.iconSize', '2rem'), _dec2 = reads('args.maxCount', 5), (_class = class ParticipantList extends Component {
  constructor(...args) {
    super(...args);

    _initializerDefineProperty(this, "iconSize", _descriptor, this);

    _initializerDefineProperty(this, "maxCount", _descriptor2, this);
  }

}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "iconSize", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "maxCount", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class)));
export { ParticipantList as default };