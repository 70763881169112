import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import BoxelInput from '../index';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import { fn } from '@ember/helper';
const InputTokenAmount = setComponentTemplate(precompileTemplate(`
  <div class="boxel-token-amount-input-group" ...attributes>
    {{svgJar @icon class="boxel-token-amount-input-group__icon" role="presentation"}}
    <BoxelInput
      class="boxel-token-amount-input-group__input"
      @id={{@id}}
      @value={{@value}}
      @required={{unless @disabled true}}
      @onInput={{@onInput}}
      @onBlur={{fn @onInput @value}}
      @invalid={{unless @disabled @invalid}}
      @errorMessage={{@errorMessage}}
      @helperText={{@helperText}}
      @disabled={{@disabled}}
      placeholder="0.00"
      autocomplete="off"
      inputmode="decimal"
      data-test-boxel-input-token-amount
    />
    <div class="boxel-token-amount-input-group__symbol">
      {{@symbol}}
    </div>
  </div>
`, {
  strictMode: true,
  scope: () => ({
    svgJar,
    BoxelInput,
    fn
  })
}), templateOnly("index", "InputTokenAmount"));
export default InputTokenAmount;