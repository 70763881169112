import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import ActionCardContainerTitle from './title';
import '@cardstack/boxel/styles/global.css';
import './index.css';
const ActionContainerSection = setComponentTemplate(precompileTemplate(`
  <section class="boxel-action-container-section" data-test-boxel-action-container-section={{@dataTestId}} ...attributes>
    {{#if @title}}
      <ActionCardContainerTitle @icon={{@icon}} @imgUrl={{@imgUrl}} @dataTestId={{@dataTestId}}>
        {{@title}}
      </ActionCardContainerTitle>
    {{/if}}

    {{yield}}
  </section>
`, {
  strictMode: true,
  scope: () => ({
    ActionCardContainerTitle
  })
}), templateOnly("index", "ActionContainerSection"));
export default ActionContainerSection;