var _dec, _class, _descriptor;

function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import { reads } from 'macro-decorators';
import '@cardstack/boxel/styles/global.css';
import './index.css';
import cn from '@cardstack/boxel/helpers/cn';
import or from 'ember-truth-helpers/helpers/or';
import not from 'ember-truth-helpers/helpers/not';
import cssVar from '@cardstack/boxel/helpers/css-var';
import cssUrl from '@cardstack/boxel/helpers/css-url';
let ParticipantComponent = setComponentTemplate(precompileTemplate(`
    {{#let (or @iconOnly (not @title)) as |iconOnly|}}
      <div
        class={{cn
          "boxel-participant"
          boxel-participant__icon-only=iconOnly
          boxel-participant__has-logo=@hasLogo
          boxel-participant__vertical=@vertical
        }}
        style={{cssVar boxel-participant-icon-size=this.iconSize}}
        ...attributes
      >
        <div class="boxel-participant__image" style={{cssUrl "background-image" @image}} />
        {{#unless iconOnly}}
          <div>
            <span class="boxel-participant__title">
              {{@title}}
            </span>
            <p class="boxel-participant__description">
              {{@description}}
            </p>
          </div>
        {{/unless}}

        {{yield}}
      </div>
    {{/let}}
  `, {
  strictMode: true,
  scope: () => ({
    or,
    not,
    cn,
    cssVar,
    cssUrl
  })
}), (_dec = reads('args.iconSize', '2rem'), (_class = class ParticipantComponent extends Component {
  constructor(...args) {
    super(...args);

    _initializerDefineProperty(this, "iconSize", _descriptor, this);
  }

}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "iconSize", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class)));
export { ParticipantComponent as default };