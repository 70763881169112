function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import '@cardstack/boxel/styles/global.css';
import './index.css';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
export default class ParticipantsSummary extends Component {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "max", 2);
  }

  get participantsString() {
    let {
      max
    } = this;
    let {
      participants
    } = this.args;

    if (!participants) {
      return null;
    }

    let parts = participants.filter(Boolean).slice(0, max).map(p => p.title);

    if (participants.length > max) {
      let remaining = participants.length - max;
      parts = parts.concat(`+${remaining}`);
    }

    return parts.join(', ');
  }

  get iconName() {
    let {
      participants
    } = this.args;
    return participants.length > 1 ? 'users' : 'user';
  }

}
setComponentTemplate(precompileTemplate(`
    <div class="boxel-participants-summary" ...attributes>
      {{svgJar this.iconName width="14px" height="12px"}}
      <span>{{this.participantsString}}</span>
    </div>
  `, {
  strictMode: true,
  scope: () => ({
    svgJar
  })
}), ParticipantsSummary);