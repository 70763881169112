import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import cn from '@cardstack/boxel/helpers/cn';
import eq from 'ember-truth-helpers/helpers/eq';
const RadioInputContainer = setComponentTemplate(precompileTemplate(`
  <fieldset class="boxel-radio-fieldset" disabled={{@disabled}} ...attributes>
    <legend class="boxel-radio-fieldset__legend">
      {{@groupDescription}}
    </legend>
    {{!-- this div is necessary because Chrome has a special case for fieldsets and it breaks grid auto placement --}}
    <div class={{cn
        "boxel-radio-fieldset__container"
        boxel-radio-fieldset__container--compact=(eq @spacing "compact")
        boxel-radio-fieldset__container--horizontal=(eq @orientation "horizontal")
        boxel-radio-fieldset__container--vertical=(eq @orientation "vertical")
      }}
    >
      {{yield}}
    </div>
  </fieldset>
`, {
  strictMode: true,
  scope: () => ({
    cn,
    eq
  })
}), templateOnly("index", "RadioInputContainer"));
export default RadioInputContainer;