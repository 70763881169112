import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import { Link } from 'ember-link';
import '@cardstack/boxel/styles/global.css';
import './index.css';
import OrgTitle from '../org-title';
import Searchbox from '../searchbox';
import cn from '@cardstack/boxel/helpers/cn';
import optional from 'ember-composable-helpers/helpers/optional';
import or from 'ember-truth-helpers/helpers/or';
import { LinkTo } from '@ember/routing'; //@ts-expect-error glint does not think this is consumed-but it is consumed in the template https://github.com/typed-ember/glint/issues/374

import { hash } from '@ember/helper';
export default class BoxelLeftMainNav extends Component {
  get linkItems() {
    return this.args.items.filter(item => item.action instanceof Link);
  }

}
setComponentTemplate(precompileTemplate(`
    <section class="boxel-left-main-nav">
      <OrgTitle
        @title={{@org.title}}
        @iconURL={{@org.iconURL}}
      />

      <Searchbox
        @hideIcon={{true}}
        @placeholder="Search"
        @label="Search accounts and members"
        @value=""
        @onChange={{optional @onSearchChange}}
        @onInput={{optional @onSearchInput}}
        class="boxel-left-main-nav__searchbox"
      />

      {{#if this.linkItems}}
        <nav class="boxel-left-main-nav__nav" role="tablist">
          {{#each this.linkItems as |link|}}
              <LinkTo
                @route={{link.action.routeName}}
                @query={{or link.action.queryParams (hash)}}
                role="tab"
                data-text={{link.text}}
                class={{cn
                  "boxel-left-main-nav__link"
                  boxel-left-main-nav__link--is-active=link.action.isActive
                }}
              >
                {{link.text}}
              </LinkTo>
          {{/each}}
        </nav>
      {{/if}}
    </section>
  `, {
  strictMode: true,
  scope: () => ({
    OrgTitle,
    Searchbox,
    optional,
    LinkTo,
    or,
    hash,
    cn
  })
}), BoxelLeftMainNav);