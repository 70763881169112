import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import SortMenuItem from './item';
import { htmlSafe } from '@ember/template';
import and from 'ember-truth-helpers/helpers/and';
import eq from 'ember-truth-helpers/helpers/eq';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import '@cardstack/boxel/styles/global.css';
import './index.css';
const MINIMUM_USEFUL_MAX_HEIGHT_PX = 150;
const VERTICAL_BUFFER_BELOW_MENU_PX = 40;
const FALLBACK_MAX_HEIGHT = '40vh';
;
export default class SortMenuComponent extends Component {
  get styleAttribute() {
    return htmlSafe(`max-height: ${this.maxHeight}`);
  }

  get maxHeight() {
    let maxHeightArg = this.args.maxHeight;

    if (maxHeightArg && maxHeightArg > MINIMUM_USEFUL_MAX_HEIGHT_PX) {
      return `${maxHeightArg - VERTICAL_BUFFER_BELOW_MENU_PX}px`;
    }

    return FALLBACK_MAX_HEIGHT;
  }

}
setComponentTemplate(precompileTemplate(`
    <div class="boxel-sort-menu" style={{this.styleAttribute}} ...attributes>
      <header>Sort by</header>
      <ul role="menu">
        {{#each @sortableColumns as |column|}}
          <SortMenuItem
            @column={{column}}
            @direction="asc"
            @isSelected={{and (eq column @sortedColumn) (eq "asc" @sortedDirection)}}
            {{on "click" (fn @onSort column "asc")}}
          />
          <SortMenuItem
            @column={{column}}
            @direction="desc"
            @isSelected={{and (eq column @sortedColumn) (eq "desc" @sortedDirection)}}
            {{on "click" (fn @onSort column "desc")}}
          />
        {{/each}}
      </ul>
    </div>    
  `, {
  strictMode: true,
  scope: () => ({
    SortMenuItem,
    and,
    eq,
    on,
    fn
  })
}), SortMenuComponent);