import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import '@cardstack/boxel/styles/global.css';
import './index.css';
import cn from '@cardstack/boxel/helpers/cn';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import { concat } from '@ember/helper';
import eq from 'ember-truth-helpers/helpers/eq';
import not from 'ember-truth-helpers/helpers/not';
const CardCatalogTrayItem = setComponentTemplate(precompileTemplate(`
  <div
    class={{cn
      "boxel-card-catalog-tray-item"
      (concat "boxel-card-catalog-tray-item--" @state)
    }}
    tabindex="0"
    ...attributes
  >
    {{svgJar @icon class="boxel-card-catalog-tray-item__icon"}}
    <h3 class="boxel-card-catalog-tray-item__title">
      {{@title}}
    </h3>
    <p class="boxel-card-catalog-tray-item__description">
      {{@description}}
    </p>
    {{#if (eq @state "used")}}
      {{svgJar
        "icon-check-circle-ht"
        class="boxel-card-catalog-tray-item__used-icon"
      }}
    {{else if (not (eq @state "dragged-in-tray"))}}
      {{svgJar "gripper" class="boxel-card-catalog-tray-item__drag-handle"}}
    {{/if}}
  </div>
`, {
  strictMode: true,
  scope: () => ({
    cn,
    concat,
    svgJar,
    eq,
    not
  })
}), templateOnly("index", "CardCatalogTrayItem"));
export default CardCatalogTrayItem;