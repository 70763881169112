import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import '@cardstack/boxel/styles/global.css';
import './index.css';
import BoxelSelect from '../../select';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import cn from '@cardstack/boxel/helpers/cn';
export default class TokenSelect extends Component {
  get placeholder() {
    return this.args.placeholder || 'Choose a token';
  }

}
setComponentTemplate(precompileTemplate(`
    <BoxelSelect
      @placeholder={{this.placeholder}}
      @options={{@tokens}}
      @selected={{@value}}
      @disabled={{@disabled}}
      @onChange={{@onChooseToken}}
      @verticalPosition="below"
      class={{cn "boxel-input-token-select" boxel-input-token-select--disabled=@disabled}}
      ...attributes
     as |item itemCssClass|>
      <div class={{cn itemCssClass "boxel-input-token-select__dropdown-item"}}>
        {{svgJar
          item.icon
          class="boxel-input-token-select__icon"
          role="presentation"
        }}
        {{item.name}}
      </div>
    </BoxelSelect>
  `, {
  strictMode: true,
  scope: () => ({
    BoxelSelect,
    cn,
    svgJar
  })
}), TokenSelect);