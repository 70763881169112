var _class;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component'; //@ts-expect-error glint does not think hash is consume but it is

import { hash, concat } from '@ember/helper';
import cn from '@cardstack/boxel/helpers/cn';
import focusTrap from 'ember-focus-trap/modifiers/focus-trap';
import { modifier as createModifier } from "ember-modifier";
import BasicDropdown from 'ember-basic-dropdown/components/basic-dropdown';
import { action } from '@ember/object';
import './index.css';
// Needs to be class, BasicDropdown doesn't work with const
let BoxelDropdown = (_class = class BoxelDropdown extends Component {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "dropdownModifier", createModifier(function (element, _positional, named) {
      const {
        dropdown,
        eventType: desiredEventType,
        stopPropagation
      } = named;

      if (element.tagName.toUpperCase() !== 'BUTTON') {
        throw new Error('Only buttons should be used with the dropdown modifier');
      }

      function updateAria() {
        element.setAttribute('aria-expanded', dropdown.isOpen ? 'true' : 'false');
        element.setAttribute('aria-disabled', dropdown.disabled ? 'true' : 'false');
      }

      function handleMouseEvent(e) {
        if (typeof document === 'undefined') return;
        if (!dropdown || dropdown.disabled) return;
        const eventType = e.type;
        const notLeftClick = e.button !== 0;
        if (eventType !== desiredEventType || notLeftClick) return;
        if (stopPropagation) e.stopPropagation();
        dropdown.actions.toggle(e);
        updateAria();
      }

      function handleKeyDown(e) {
        const {
          disabled,
          actions
        } = dropdown;
        if (disabled) return;

        if (e.keyCode === 27) {
          actions.close(e);
        }

        updateAria();
      }

      element.addEventListener('click', handleMouseEvent);
      element.addEventListener('keydown', handleKeyDown);
      element.setAttribute('data-ebd-id', `${dropdown.uniqueId}-trigger`);
      element.setAttribute('aria-owns', `ember-basic-dropdown-content-${dropdown.uniqueId}`);
      element.setAttribute('aria-controls', `ember-basic-dropdown-content-${dropdown.uniqueId}`);
      updateAria();
      return function cleanup() {
        element.removeEventListener('click', handleMouseEvent);
        element.removeEventListener('keydown', handleKeyDown);
      };
    }, {
      eager: false
    }));
  }

  registerAPI(publicAPI) {
    this.args.registerAPI?.(publicAPI);
  }

}, (_applyDecoratedDescriptor(_class.prototype, "registerAPI", [action], Object.getOwnPropertyDescriptor(_class.prototype, "registerAPI"), _class.prototype)), _class);
setComponentTemplate(precompileTemplate(`
    {{!--
      Note:
      ...attributes will only apply to BasicDropdown if @renderInPlace={{true}}
      because otherwise it does not render any HTML elements of its own, only its yielded content
    --}}
    <BasicDropdown
      @registerAPI={{this.registerAPI}}
      @onClose={{@onClose}}
      as |dd|
    >
      {{yield (modifier
        this.dropdownModifier
        dropdown=dd
        eventType="click"
        stopPropagation=false
      ) to="trigger"}}
      <dd.Content
        data-test-boxel-dropdown-content
        class={{cn "boxel-dropdown__content" @contentClass}}
        {{focusTrap
          isActive=dd.isOpen
          focusTrapOptions=(hash
            initialFocus=(concat "[aria-controls='ember-basic-dropdown-content-" dd.uniqueId "']")
            onDeactivate=dd.actions.close
            allowOutsideClick=true
          )
        }}
      >
        {{yield (hash close=dd.actions.close) to="content"}}
      </dd.Content>
    </BasicDropdown>
  `, {
  strictMode: true,
  scope: () => ({
    BasicDropdown,
    dd,
    cn,
    focusTrap,
    hash,
    concat
  })
}), BoxelDropdown);
export default BoxelDropdown;