import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import BoxelOrgTitle from '../org-title';
import cssVar from '@cardstack/boxel/helpers/css-var';
import '@cardstack/boxel/styles/global.css';
import './index.css';
export default class BoxelOrgHeader extends Component {}
setComponentTemplate(precompileTemplate(`
    <section class="boxel-org-header" ...attributes>
      <BoxelOrgTitle
        @iconURL={{@iconURL}}
        @title={{@title}}
        @subtitle={{@subtitle}}
        style={{cssVar
          boxel-org-title-text-color="var(--boxel-org-header-color)"
          boxel-org-title-logo-position="var(--boxel-org-header-logo-position)"
          boxel-org-title-logo-size="var(--boxel-org-header-logo-size)"
        }}
      />

      {{yield}}
    </section>
  `, {
  strictMode: true,
  scope: () => ({
    BoxelOrgTitle,
    cssVar
  })
}), BoxelOrgHeader);