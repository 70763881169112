var _class, _descriptor;

function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import BoxelSelect from '@cardstack/boxel/components/boxel/select';
import cn from '@cardstack/boxel/helpers/cn';
import or from 'ember-truth-helpers/helpers/or';
import './index.css';
let BoxelInputRangedNumberPicker = setComponentTemplate(precompileTemplate(`
    <BoxelSelect
      class={{cn
        "boxel-ranged-number-picker"
        boxel-ranged-number-picker--selected=this.selectedNumber
      }}
      ...attributes
      @options={{this.rangedNumbers}}
      @selected={{this.selectedNumber}}
      @placeholder={{or @placeholder "Pick Number"}}
      @onChange={{this.setSelectedNumber}}
      @renderInPlace={{true}}
      @dropdownClass="boxel-ranged-number-picker__dropdown"
      @triggerComponent={{@triggerComponent}}
      as |item itemCssClass|
    >
      <div class={{cn itemCssClass "boxel-ranged-number-picker__item"}}>
        {{item}}
      </div>
    </BoxelSelect>
  `, {
  strictMode: true,
  scope: () => ({
    BoxelSelect,
    cn,
    or
  })
}), (_class = class BoxelInputRangedNumberPicker extends Component {
  constructor(...args) {
    super(...args);

    _initializerDefineProperty(this, "selectedNumber", _descriptor, this);
  }

  setSelectedNumber(number) {
    this.selectedNumber = number;
    this.args.onNumberSelected(parseInt(number));
  }

  get rangedNumbers() {
    const {
      min,
      max
    } = this.args;
    const length = max - min + 1;
    const items = Array.from({
      length
    }, (_, i) => {
      const currentNumber = min + i;
      return currentNumber.toString();
    });
    return items;
  }

}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedNumber", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return undefined;
  }
}), _applyDecoratedDescriptor(_class.prototype, "setSelectedNumber", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedNumber"), _class.prototype)), _class));
export { BoxelInputRangedNumberPicker as default };