import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import cn from '@cardstack/boxel/helpers/cn';
import not from 'ember-truth-helpers/helpers/not';
import or from 'ember-truth-helpers/helpers/or';
import '@cardstack/boxel/styles/global.css';
import './index.css';
export default class ProgressIcon extends Component {
  get elementStyle() {
    let {
      size
    } = this.args;
    let styles = [`width: ${size}px`, `height: ${size}px`];

    if (this.args.isCancelled || this.args.isComplete) {
      styles.push(`background-size: ${size * 0.666}px ${size * 0.666}px`);
    }

    return htmlSafe(styles.join(';'));
  }

  get pieStyle() {
    return htmlSafe(`stroke-dasharray:${this.args.fractionComplete * 60} 60`);
  }

  get sizeString() {
    return this.args.size.toString();
  }

}
setComponentTemplate(precompileTemplate(`
    <div
      class={{cn
        "boxel-progress-icon"
        boxel-progress-icon--cancelled=@isCancelled
        boxel-progress-icon--complete=@isComplete
      }}
      style={{this.elementStyle}}
      ...attributes
    >
      {{#if (not (or @isCancelled @isComplete))}}
        <div class="boxel-progress-icon__progress-pie" style={{this.pieStyle}}>
          {{svgJar "progress-circle" width=this.sizeString height=this.sizeString}}
        </div>
      {{/if}}
    </div>
  `, {
  strictMode: true,
  scope: () => ({
    cn,
    not,
    or,
    svgJar
  })
}), ProgressIcon);