import dayjs from 'dayjs';
import { helper } from '@ember/component/helper';
const DEFAULT_LOCALE = 'en';
const DEFAULT_OUTPUT_FORMAT = 'D MMMM, YYYY';
export function dayjsFormat(date, formatString = DEFAULT_OUTPUT_FORMAT, locale = DEFAULT_LOCALE, option) {
  if (option) {
    return dayjs(date, option).locale(locale).format(formatString);
  } else {
    return dayjs(date).locale(locale).format(formatString);
  }
}
export default helper(function computed(positional, hash) {
  return dayjsFormat(positional[0], positional[1], hash.locale, positional[3]);
});