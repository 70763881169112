function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { getMethodNames } from '@cardstack/boxel/utils/reflection';

class BaseKeyboardHandler {
  constructor(component) {
    _defineProperty(this, "component", void 0);

    this.component = component;
  }

  handle(ev) {
    let unhandled = false;
    let handlerMethod = this.handlerMethodFromEventCode(ev.code);

    if (handlerMethod) {
      handlerMethod.call(this);
    } else if (/^Digit[0-9]$/.test(ev.code)) {
      this.onNumberKey(ev);
    } else {
      unhandled = true;
    }

    if (!unhandled) {
      ev.stopPropagation();
      ev.preventDefault();
    }
  }

  onEnter() {
    this.component.dropdownAPI?.actions?.close();
  }

  onKeyA() {
    this.component.keyboardFocus = 'meridian';
    this.component.setMeridian('am');
  }

  onKeyP() {
    this.component.keyboardFocus = 'meridian';
    this.component.setMeridian('pm');
  }

  handlerMethodFromEventCode(eventCode) {
    const isValidMethodKey = methodName => getMethodNames(this).includes(methodName);

    let handlerMethodName = `on${eventCode}`;

    if (isValidMethodKey(handlerMethodName)) {
      let candidate = this[handlerMethodName];

      if (typeof candidate === 'function') {
        return candidate;
      }
    }

    return undefined;
  }

}

export class NoneFocusKeyboardHandler extends BaseKeyboardHandler {
  onArrowUp() {
    this.component.keyboardFocus = 'hour';
    this.component.decrementHour();
  }

  onArrowDown() {
    this.component.keyboardFocus = 'hour';
    this.component.incrementHour();
  }

  onArrowLeft() {
    this.component.keyboardFocus = 'hour';
  }

  onArrowRight() {
    this.component.keyboardFocus = 'hour';
  }

  onNumberKey(ev) {
    this.component.keyboardFocus = 'hour';
    let numString = ev.code.replace('Digit', '');
    this.component.updateHourFromNumericInput(numString);
  }

}
export class HourFocusKeyboardHandler extends BaseKeyboardHandler {
  handle(ev) {
    if (ev.code === 'Semicolon' && ev.shiftKey) {
      ev.stopPropagation();
      ev.preventDefault();
      this.onColon();
    } else {
      super.handle(ev);
    }
  }

  onArrowUp() {
    this.component.decrementHour();
  }

  onArrowDown() {
    this.component.incrementHour();
  }

  onArrowLeft() {// no-op
  }

  onArrowRight() {
    this.component.keyboardFocus = 'minute';
  }

  onColon() {
    this.component.keyboardFocus = 'minute';
  }

  onNumberKey(ev) {
    let numString = ev.code.replace('Digit', '');
    this.component.updateHourFromNumericInput(numString);
  }

}
export class MinuteFocusKeyboardHandler extends BaseKeyboardHandler {
  onArrowUp() {
    this.component.decrementMinute();
  }

  onArrowDown() {
    this.component.incrementMinute();
  }

  onArrowLeft() {
    this.component.keyboardFocus = 'hour';
  }

  onArrowRight() {
    this.component.keyboardFocus = 'meridian';
  }

  onNumberKey(ev) {
    let numString = ev.code.replace('Digit', '');
    this.component.updateMinuteFromNumericInput(numString);
  }

}
export class MeridianFocusKeyboardHandler extends BaseKeyboardHandler {
  onArrowUp() {
    this.component.toggleMeridian();
  }

  onArrowDown() {
    this.component.toggleMeridian();
  }

  onArrowLeft() {
    this.component.keyboardFocus = 'minute';
  }

  onArrowRight() {// no-op
  }

  onNumberKey(ev) {
    this.component.keyboardFocus = 'hour';
    let numString = ev.code.replace('Digit', '');
    this.component.updateHourFromNumericInput(numString);
  }

}