import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import '@cardstack/boxel/styles/global.css';
import './index.css';
export default class BoxelCardManagementButton extends Component {}
setComponentTemplate(precompileTemplate(`
    <button
      class="boxel-left-edge-nav-card-management-button"
      ...attributes
    >
      {{yield}}
    </button>
  `, {
  strictMode: true
}), BoxelCardManagementButton);