import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import cn from '@cardstack/boxel/helpers/cn';
import '@cardstack/boxel/styles/global.css';
import './index.css';
export default class SelectableButton extends Component {}
setComponentTemplate(precompileTemplate(`
    <button
      class={{cn
        "boxel-left-edge-nav-selectable-button"
        boxel-left-edge-nav-selectable-button--selected=@isSelected
      }}
      ...attributes
    >
      {{yield}}
    </button>
  `, {
  strictMode: true,
  scope: () => ({
    cn
  })
}), SelectableButton);