import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import '@cardstack/boxel/styles/global.css';
import './index.css';
const ExpandableBanner = setComponentTemplate(precompileTemplate(`
  <details class="boxel-expandable-banner" ...attributes>
    <summary class="boxel-expandable-banner__summary">
      <div class="boxel-expandable-banner__summary-layout">
      {{svgJar @icon class="boxel-expandable-banner__summary-icon"}}
      <span class="boxel-expandable-banner__summary-text">
        {{@summary}}
      </span>
      <div class="boxel-expandable-banner__summary-marker" aria-hidden="true">
        {{svgJar "caret-up" class="boxel-expandable-banner__summary-marker-icon"}}
      </div>
      </div>
    </summary>
    <div class="boxel-expandable-banner__content">
      {{yield}}
    </div>
  </details>
`, {
  strictMode: true,
  scope: () => ({
    svgJar
  })
}), templateOnly("index", "ExpandableBanner"));
export default ExpandableBanner;