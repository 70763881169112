import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';

function formatValue(value) {
  if (typeof value === 'function') {
    value = value();
  }

  if (typeof value === 'number') {
    value = value.toString();
  }

  return value;
}

export function cssVar(values) {
  let vars = [];
  Object.keys(values).forEach(name => {
    if (values[name] !== undefined) {
      vars.push(`--${name}: ${formatValue(values[name])}`);
    }
  });
  return vars.join('; ');
}
export default helper(function (_params, hash) {
  return htmlSafe(cssVar(hash));
});