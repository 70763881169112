import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import cn from '@cardstack/boxel/helpers/cn';
import cssUrl from "@cardstack/boxel/helpers/css-url";
import '@cardstack/boxel/styles/global.css';
import './index.css';
export default class BoxelOrgTitle extends Component {}
setComponentTemplate(precompileTemplate(`
    <header class={{cn
      "boxel-org-title"
      boxel-org-title--has-logo=@iconURL
      boxel-org-title--has-subtitle=@subtitle
    }} ...attributes>
      {{#if @iconURL}}
        <span class="boxel-org-title__logo" style={{cssUrl "background-image" @iconURL}} />
      {{/if}}
      <h1 class="boxel-org-title__title">
        {{@title}}
      </h1>
      {{#if @subtitle}}
        <h2 class="boxel-org-title__subtitle">
          {{@subtitle}}
        </h2>
      {{/if}}
    </header>
  `, {
  strictMode: true,
  scope: () => ({
    cn,
    cssUrl
  })
}), BoxelOrgTitle);