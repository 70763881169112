import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import BoxelIconButton from '../../icon-button';
import BoxelButton from '../../button';
import BoxelSelect from '../../select';
export const ButtonAccessory = setComponentTemplate(precompileTemplate(`
  <BoxelButton
    class="boxel-input-group__accessory boxel-input-group__button-accessory"
    @kind={{@kind}}
    data-test-boxel-input-group-button-accessory
    ...attributes
  >
    {{yield}}
  </BoxelButton>
`, {
  strictMode: true,
  scope: () => ({
    BoxelButton
  })
}), templateOnly("index", "ButtonAccessory"));
export const IconButtonAccessory = setComponentTemplate(precompileTemplate(`
  <BoxelIconButton
    class="boxel-input-group__accessory boxel-input-group__icon-button-accessory"
    @icon={{@icon}}
    @height={{@height}}
    @width={{@width}}
    data-test-boxel-input-group-icon-button-accessory
    ...attributes
  />
`, {
  strictMode: true,
  scope: () => ({
    BoxelIconButton
  })
}), templateOnly("index", "IconButtonAccessory"));
export const TextAccessory = setComponentTemplate(precompileTemplate(`
  <span
    class="boxel-input-group__accessory boxel-input-group__text-accessory"
    data-test-boxel-input-group-text-accessory
    ...attributes
  >{{yield}}</span>
`, {
  strictMode: true
}), templateOnly("index", "TextAccessory"));
export const SelectAccessory = setComponentTemplate(precompileTemplate(`
  <div class="boxel-input-group__accessory boxel-input-group__select-accessory" data-test-boxel-input-group-select-accessory>
    <BoxelSelect
      @disabled={{@disabled}}
      @dropdownClass={{@dropdownClass}}
      @placeholder={{@placeholder}}
      @options={{@options}}
      @selected={{@selected}}
      @onChange={{@onChange}}
      data-test-boxel-input-group-select-accessory-trigger
      ...attributes
    as |item itemCssClass|>
      {{#if (has-block)}}
        {{yield item itemCssClass}}
      {{else}}
        <div class={{itemCssClass}}>{{item}}</div>
      {{/if}}
    </BoxelSelect>
  </div>
`, {
  strictMode: true,
  scope: () => ({
    BoxelSelect
  })
}), templateOnly("index", "SelectAccessory"));