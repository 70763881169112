import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import pick from 'ember-composable-helpers/helpers/pick';
import { on } from '@ember/modifier';
import optional from 'ember-composable-helpers/helpers/optional';
export const InputControl = setComponentTemplate(precompileTemplate(`
  <input
    class="boxel-input-group__form-control"
    placeholder={{@placeholder}}
    value={{@value}}
    disabled={{@disabled}}
    readonly={{@readonly}}
    required={{@required}}
    {{on "input" (pick "target.value" (optional @onInput))}}
    {{on "blur" (optional @onBlur)}}
    ...attributes
  />
`, {
  strictMode: true,
  scope: () => ({
    on,
    pick,
    optional
  })
}), templateOnly("index", "InputControl"));
export const TextareaControl = setComponentTemplate(precompileTemplate(`
  <textarea class="boxel-input-group__form-control" ...attributes></textarea>
`, {
  strictMode: true
}), templateOnly("index", "TextareaControl"));