import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import cn from '@cardstack/boxel/helpers/cn';
import '@cardstack/boxel/styles/global.css';
import './index.css';
const Header = setComponentTemplate(precompileTemplate(`
  <header class={{cn
      "boxel-header"
      boxel-header--no-background=@noBackground
      boxel-header--highlighted=@isHighlighted
    }}
    data-test-boxel-header
    ...attributes
  >
    {{#if @header}}
      <span data-test-boxel-header-label>
        {{@header}}
      </span>
    {{/if}}

    {{yield}}

    {{#if (has-block "actions")}}
      <div class="boxel-header__content" data-test-boxel-header-content>
        {{yield to="actions"}}
      </div>
    {{/if}}
  </header>
`, {
  strictMode: true,
  scope: () => ({
    cn
  })
}), templateOnly("index", "Header"));
export default Header;