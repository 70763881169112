var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import Component from '@glimmer/component';
import BoxelLoadingIndicator from '../loading-indicator';
import QRCodeStyling from 'qr-code-styling';
import { reads } from 'macro-decorators';
import Modifier from 'ember-modifier';
import './index.css';
;

class RenderQrCodeModifier extends Modifier {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "qrCode", void 0);
  }

  modify(element, [options]) {
    if (this.qrCode) {
      this.qrCode.update(options);
    } else {
      this.qrCode = new QRCodeStyling(options);
      this.qrCode.append(element);
    }
  }

}

let StyledQrCodeComponent = setComponentTemplate(precompileTemplate(`
    <div
      class="boxel-styled-qr-code"
      data-test-boxel-styled-qr-code={{@data}}
      ...attributes
    >
      {{yield to="before"}}

      <div class="boxel-styled-qr-code__canvas" {{RenderQrCodeModifier this.qrOptions}}>
        <BoxelLoadingIndicator
          class="boxel-styled-qr-code__loading-indicator"
          data-test-boxel-styled-qr-code-loading-indicator
        />
      </div>

      {{#if (has-block)}}
        {{yield}}
      {{/if}}
    </div>
  `, {
  strictMode: true,
  scope: () => ({
    RenderQrCodeModifier,
    BoxelLoadingIndicator
  })
}), (_dec = reads('args.size', 300), _dec2 = reads('args.backgroundColor', '#ffffff'), _dec3 = reads('args.dotColor', '#000000'), _dec4 = reads('args.dotType', 'square'), _dec5 = reads('args.margin', 0), _dec6 = reads('args.imageMargin', 0), (_class = class StyledQrCodeComponent extends Component {
  constructor(...args) {
    super(...args);

    _initializerDefineProperty(this, "size", _descriptor, this);

    _initializerDefineProperty(this, "backgroundColor", _descriptor2, this);

    _initializerDefineProperty(this, "dotColor", _descriptor3, this);

    _initializerDefineProperty(this, "dotType", _descriptor4, this);

    _initializerDefineProperty(this, "margin", _descriptor5, this);

    _initializerDefineProperty(this, "imageMargin", _descriptor6, this);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get qrOptions() {
    return {
      width: this.size,
      height: this.size,
      margin: this.margin,
      data: this.args.data,
      image: this.args.image,
      dotsOptions: {
        color: this.dotColor,
        type: this.dotType
      },
      backgroundOptions: {
        color: this.backgroundColor
      },
      cornersDotOptions: {
        color: this.args.cornerDotColor,
        type: this.args.cornerDotType
      },
      cornersSquareOptions: {
        color: this.args.cornerSquareColor,
        type: this.args.cornerSquareType
      },
      imageOptions: {
        //   crossOrigin: 'anonymous',
        margin: this.imageMargin,
        hideBackgroundDots: false
      }
    };
  }

}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "size", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "backgroundColor", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dotColor", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dotType", [_dec4], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "margin", [_dec5], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "imageMargin", [_dec6], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class)));
export { StyledQrCodeComponent as default };