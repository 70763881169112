const isGetter = (obj, name) => !!(Object.getOwnPropertyDescriptor(obj, name) || {}).get;

const isFunction = (obj, name) => typeof obj[name] === 'function';

const deepFunctions = obj => {
  if (!obj || obj === Object.prototype) return null;
  return Object.getOwnPropertyNames(obj).filter(name => isGetter(obj, name) || isFunction(obj, name)).concat(deepFunctions(Object.getPrototypeOf(obj)) || []);
};

const distinctDeepFunctions = obj => Array.from(new Set(deepFunctions(obj)));

export const getMethodNames = obj => distinctDeepFunctions(obj).filter(name => name !== 'constructor' && !~name.indexOf('__'));