var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { reads } from 'macro-decorators';
import cssVar from '@cardstack/boxel/helpers/css-var';
import '@cardstack/boxel/styles/global.css';
import './index.css';
import { concat, fn } from '@ember/helper';
let CoverArt = setComponentTemplate(precompileTemplate(`
    <div
      class="boxel-cover-art"
      style={{cssVar
        cover-art-width=(concat this.maxWidth "px")
        cover-art-size=(concat this.size "px")
        cover-art-count=this.coverCount
      }}
      ...attributes
    >
      {{#each @covers as |art i|}}
        <div
          class="boxel-cover-art__container boxel-cover-art__container--{{i}}"
          style={{cssVar
            cover-art-index=i
            cover-art-left=(fn this.coverArtLeftPx i this.coverArtSpacing)
            cover-art-cover-size=(fn this.coverArtSizePx i)
            cover-art-spacing-multiplier=this.spacingMultiplier
          }}
        >
          <img class="boxel-cover-art__cover" src={{art}} alt="cover art" />
        </div>
      {{/each}}
    </div>
  `, {
  strictMode: true,
  scope: () => ({
    cssVar,
    concat,
    fn
  })
}), (_dec = reads('args.size', 80), _dec2 = reads('args.maxWidth', 190), _dec3 = reads('args.spacingMultiplier', 1), (_class = class CoverArt extends Component {
  constructor(...args) {
    super(...args);

    _initializerDefineProperty(this, "size", _descriptor, this);

    _initializerDefineProperty(this, "maxWidth", _descriptor2, this);

    _initializerDefineProperty(this, "spacingMultiplier", _descriptor3, this);
  }

  coverArtSize(index) {
    return this.size * 0.8 ** index;
  }

  coverArtSizePx(index) {
    return `${this.coverArtSize(index)}px`;
  }

  coverArtLeft(index, spacing) {
    return this.size * 0.8 * index * 0.85 ** index * spacing;
  }

  coverArtLeftPx(index, spacing) {
    return `${this.coverArtLeft(index, spacing)}px`;
  }

  get coverCount() {
    return this.args.covers.length;
  }

  get coverArtSpacing() {
    let {
      maxWidth,
      args: {
        covers
      }
    } = this;
    let naturalWidth = this.coverArtSize(covers.length) + this.coverArtLeft(covers.length, 1.0); // approx

    let spacingRatio = maxWidth / naturalWidth;
    return Math.min(1, spacingRatio);
  }

}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "size", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "maxWidth", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "spacingMultiplier", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "coverArtSize", [action], Object.getOwnPropertyDescriptor(_class.prototype, "coverArtSize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "coverArtSizePx", [action], Object.getOwnPropertyDescriptor(_class.prototype, "coverArtSizePx"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "coverArtLeft", [action], Object.getOwnPropertyDescriptor(_class.prototype, "coverArtLeft"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "coverArtLeftPx", [action], Object.getOwnPropertyDescriptor(_class.prototype, "coverArtLeftPx"), _class.prototype)), _class)));
export { CoverArt as default };