import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import LeftEdgeNavSelectableButton from '../../left-edge-nav/selectable-button';
import { concat } from '@ember/helper';
import or from 'ember-truth-helpers/helpers/or';
import cn from '@cardstack/boxel/helpers/cn';
import cssUrl from '@cardstack/boxel/helpers/css-url';
import cssVar from '@cardstack/boxel/helpers/css-var';
import truncate from '@cardstack/boxel/helpers/truncate';
import '@cardstack/boxel/styles/global.css';
import './index.css';
export default class BoxelOrgSwitcherButton extends Component {}
setComponentTemplate(precompileTemplate(`
    <LeftEdgeNavSelectableButton
      @isSelected={{@isSelected}}
      style={{cssVar boxel-org-switcher-button-background-color=(or @org.brandColor "var(--boxel-dark)")}}
      ...attributes
    >
      <div
        class={{cn "boxel-org-switcher-button__logo" (concat "boxel-org-switcher-button__logo--" @org.id)}}
        style={{cssUrl "background-image" @org.iconURL}}
      >
        {{#unless @org.iconURL}}
          {{truncate @org.title 1 false}}
        {{/unless}}
      </div>
    </LeftEdgeNavSelectableButton>
  `, {
  strictMode: true,
  scope: () => ({
    LeftEdgeNavSelectableButton,
    cssVar,
    or,
    cn,
    concat,
    cssUrl,
    truncate
  })
}), BoxelOrgSwitcherButton);