import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import './index.css';
import cn from '@cardstack/boxel/helpers/cn';
const SidebarCardContainer = setComponentTemplate(precompileTemplate(`

  <div class={{cn
      "boxel-sidebar-card-container"
      boxel-sidebar-card-container--attach-next=@attachNext
    }}
    ...attributes
  >
    {{#if @header}}
      <header class="boxel-sidebar-card-container__header">
        {{@header}}
      </header>
    {{/if}}

    <div class="boxel-sidebar-card-container__content">
      {{yield}}
    </div>
  </div>
  `, {
  strictMode: true,
  scope: () => ({
    cn
  })
}), templateOnly("index", "SidebarCardContainer"));
export default SidebarCardContainer;