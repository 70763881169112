import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import BoxelInput from '../index';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
;
export default class BoxelInputValidationState extends Component {
  get icon() {
    if (this.args.disabled) {
      return '';
    }

    switch (this.args.state) {
      case 'valid':
        return 'success-bordered';

      case 'invalid':
        return 'failure-bordered';

      case 'loading':
        return 'loading-indicator';

      case 'initial':
        return '';

      default:
        return '';
    }
  }

  get isInvalid() {
    return this.args.state === 'invalid';
  }

}
setComponentTemplate(precompileTemplate(`
    <div class="boxel-validation-state-input-group" ...attributes>
      <BoxelInput
        class="boxel-validation-state-input-group__input"
        @id={{@id}}
        @value={{@value}}
        @required={{unless @disabled true}}
        @onInput={{@onInput}}
        @onBlur={{@onBlur}}
        @invalid={{unless @disabled this.isInvalid}}
        @disabled={{@disabled}}
        @errorMessage={{@errorMessage}}
        @helperText={{@helperText}}
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        data-test-boxel-input-validation-state={{if @disabled true @state}}
      />
      {{#if this.icon}}
        {{svgJar this.icon class="boxel-validation-state-input-group__icon" role="presentation"}}
      {{/if}}
    </div>
  `, {
  strictMode: true,
  scope: () => ({
    BoxelInput,
    svgJar
  })
}), BoxelInputValidationState);