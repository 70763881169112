import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import or from 'ember-truth-helpers/helpers/or';
import cssVar from '@cardstack/boxel/helpers/css-var';
import '@cardstack/boxel/styles/global.css';
import './index.css';
const LoadingIndicator = setComponentTemplate(precompileTemplate(`
  <div class="boxel-loading-indicator" ...attributes>
    {{svgJar "loading-indicator" style=(cssVar icon-color=(or @color "#000")) role="presentation"}}
  </div>
`, {
  strictMode: true,
  scope: () => ({
    svgJar,
    cssVar,
    or
  })
}), templateOnly("index", "LoadingIndicator"));
export default LoadingIndicator;