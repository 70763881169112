import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import BoxelSelectButton from '../select-button';
import BoxelDropdownButton from '../dropdown-button';
import cn from '@cardstack/boxel/helpers/cn';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import { on } from '@ember/modifier';
import '@cardstack/boxel/styles/global.css';
import './index.css';
export default class SelectionControlGroup extends Component {
  get isPartial() {
    return !!this.args.selectedItemCount;
  }

}
setComponentTemplate(precompileTemplate(`
    <div
      class="boxel-selection-control-group"
      data-test-boxel-selection-control-group={{if
        @isSelected
        "selected"
        (if @selectedItemCount "partial" "none")
      }}
      ...attributes
    >
      <BoxelSelectButton
        class="boxel-selection-control-group__select-button"
        @mode={{@mode}}
        @isPartial={{this.isPartial}}
        @isSelected={{@isSelected}}
        data-test-boxel-selection-control-group-toggle
        {{on "click" @toggleSelectAll}}
      />
      <div
        class={{cn
          "boxel-selection-control-group__select-all"
          boxel-selection-control-group__select-all--selected-items=this.isPartial
        }}
      >
        {{#if @selectedItemCount}}
          {{svgJar "check-mark" width="9px" height="7px"}}
          {{@selectedItemCount}}
          selected
          {{#if @menuComponent}}
            <BoxelDropdownButton
              @label="more actions"
              @icon="more-actions"
              class="boxel-selection-control-group__menu-trigger"
              as |ddb|
            >
              <@menuComponent @closeMenu={{ddb.close}} />
            </BoxelDropdownButton>
          {{/if}}
        {{else}}
          Select all
        {{/if}}
      </div>
    </div>
  `, {
  strictMode: true,
  scope: () => ({
    BoxelSelectButton,
    on,
    cn,
    svgJar,
    BoxelDropdownButton
  })
}), SelectionControlGroup);