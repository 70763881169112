import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import '@cardstack/boxel/styles/global.css';
import './index.css';
const MilestoneBanner = setComponentTemplate(precompileTemplate(`
  <div class="boxel-milestone-banner" ...attributes>
    <span class="boxel-milestone-banner__title">{{@title}}</span>
    <span class="boxel-milestone-banner__status">{{@status}}</span>
  </div>
`, {
  strictMode: true
}), templateOnly("index", "MilestoneBanner"));
export default MilestoneBanner;