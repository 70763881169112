import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import eq from 'ember-truth-helpers/helpers/eq';
import cn from '@cardstack/boxel/helpers/cn';
;
const SortMenu = setComponentTemplate(precompileTemplate(`
  <li
    class={{cn "sort-menu-item" sort-menu-item__active=@isSelected}}
    role="menuitem"
    data-test-boxel-sort-menu-item-column={{@column.name}}
    data-test-boxel-sort-menu-item-direction={{@direction}}
    ...attributes
  >
    {{@column.name}}
    <span>
      {{#if (eq @column.sortType "numeric")}}
        {{#if (eq @direction "asc")}}
          1 {{svgJar "arrow-right"}} 9
        {{else}}
          9 {{svgJar "arrow-right"}} 1
        {{/if}}
      {{else}}
        {{#if (eq @direction "asc")}}
          A {{svgJar "arrow-right"}} Z
        {{else}}
          Z {{svgJar "arrow-right"}} A
        {{/if}}
      {{/if}}
    </span>
  </li>
`, {
  strictMode: true,
  scope: () => ({
    cn,
    eq,
    svgJar
  })
}), templateOnly("index", "SortMenu"));
export default SortMenu;