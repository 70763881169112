import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import '@cardstack/boxel/styles/global.css';
import './index.css';
import { dayjsFormat } from '@cardstack/boxel/helpers/dayjs-format';
import now from '@cardstack/boxel/helpers/now';
import or from 'ember-truth-helpers/helpers/or';
const DateDivider = setComponentTemplate(precompileTemplate(`
  <div class="boxel-date-divider" ...attributes>
    <hr class="boxel-date-divider__hr">
    <time class="boxel-date-divider__date">{{dayjsFormat (or @date (now)) "MMMM D, YYYY"}}</time>
  </div>
`, {
  strictMode: true,
  scope: () => ({
    dayjsFormat,
    or,
    now
  })
}), templateOnly("index", "DateDivider"));
export default DateDivider;