import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import or from 'ember-truth-helpers/helpers/or';
import { concat } from '@ember/helper';
import cn from '@cardstack/boxel/helpers/cn';
import '@cardstack/boxel/styles/global.css';
import './index.css';
const IconButton = setComponentTemplate(precompileTemplate(`
  <button class={{cn
    "boxel-icon-button"
    (if @variant (concat "boxel-icon-button--" @variant))
    @class
  }} ...attributes>
    {{#if @icon}}
      {{svgJar @icon width=(or @width "16px") height=(or @height "16px")}}
    {{/if}}
  </button>
`, {
  strictMode: true,
  scope: () => ({
    cn,
    concat,
    svgJar,
    or
  })
}), templateOnly("index", "IconButton"));
export default IconButton;