var _dec, _class, _descriptor;

function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import { or } from 'macro-decorators';
import { htmlSafe } from '@ember/template';
import { concat } from '@ember/helper';
import '@cardstack/boxel/styles/global.css';
import './index.css';
let ProgressCircle = setComponentTemplate(precompileTemplate(`
    <div
      class="boxel-progress-circle"
      style={{htmlSafe (concat "width:" this.size "px;height:" this.size "px;")}}
      ...attributes
    >
      <div
        class="boxel-progress-circle__pie"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width={{this.size}} height={{this.size}} viewBox="0 0 {{this.outerCircleDiameter}} {{this.outerCircleDiameter}}">
          <g stroke-width="{{this.progressArcThickness}}">
            <circle
              cx={{this.outerCircleRadius}}
              cy={{this.outerCircleRadius}}
              r={{this.strokeCircleRadius}}
              class="boxel-progress-circle__background-circle"
            />
            <circle
              cx={{this.outerCircleRadius}}
              cy={{this.outerCircleRadius}}
              r={{this.strokeCircleRadius}}
              class="boxel-progress-circle__indicator-circle"
              style={{this.pieStyle}}
            />
          </g>
        </svg>
      </div>
      <div
        class="boxel-progress-circle__pct-label"
        style={{htmlSafe (concat "font-size:" this.fontSize "px;width:" this.percentLabelDiameter "px; height:" this.percentLabelDiameter "px;")}}
      >
        {{this.humanPercentComplete}}%
      </div>
    </div>

  `, {
  strictMode: true,
  scope: () => ({
    htmlSafe,
    concat
  })
}), (_dec = or('args.size', 'outerCircleDiameter'), (_class = class ProgressCircle extends Component {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "progressArcThickness", 12);

    _defineProperty(this, "outerCircleRadius", 60);

    _defineProperty(this, "innerCircleRadius", this.outerCircleRadius - this.progressArcThickness);

    _defineProperty(this, "strokeCircleRadius", (this.outerCircleRadius + this.innerCircleRadius) / 2);

    _defineProperty(this, "outerCircleDiameter", this.outerCircleRadius * 2);

    _defineProperty(this, "innerCircleDiameter", this.innerCircleRadius * 2);

    _defineProperty(this, "strokeCircleCircumference", this.strokeCircleRadius * 2 * Math.PI);

    _initializerDefineProperty(this, "size", _descriptor, this);
  }

  get pieStyle() {
    return htmlSafe(`stroke-dasharray: ${this.progressArcLength} ${this.strokeCircleCircumference}`);
  }

  get progressArcLength() {
    return this.args.percentComplete / 100 * this.strokeCircleCircumference;
  }

  get scale() {
    return this.size / this.outerCircleDiameter;
  }

  get fontSize() {
    return this.scale * 25;
  }

  get percentLabelDiameter() {
    return this.scale * this.innerCircleDiameter;
  }

  get humanPercentComplete() {
    if (this.args.percentComplete) {
      return Math.round(this.args.percentComplete);
    }

    return 0;
  }

}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "size", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class)));
export { ProgressCircle as default };