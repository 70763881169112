import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import '@cardstack/boxel/styles/global.css';
import './index.css';
import cn from '@cardstack/boxel/helpers/cn';
import BoxelHeader from '../header';
const CardContainer = setComponentTemplate(precompileTemplate(`
  <article class={{cn
      "boxel-card-container"
      boxel-card-container--highlighted=@isHighlighted
      boxel-card-container--boundaries=@displayBoundaries
    }}
    data-test-boxel-card-container
    ...attributes
  >
    {{#if (has-block "header")}}
      <BoxelHeader @header={{@header}}>
        {{yield to="header"}}
      </BoxelHeader>
    {{/if}}

    {{yield}}
  </article>
`, {
  strictMode: true,
  scope: () => ({
    cn,
    BoxelHeader
  })
}), templateOnly("index", "CardContainer"));
export default CardContainer;