import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import cn from '@cardstack/boxel/helpers/cn';
import { concat } from '@ember/helper';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import '@cardstack/boxel/styles/global.css';
import './index.css';
const DropTarget = setComponentTemplate(precompileTemplate(`
  <div
    class={{cn
      "boxel-drop-target"
      (concat "boxel-drop-target--" @state)
    }}
    ...attributes
  >
    {{svgJar "icon-plus-circle-highlight-bg" width="30" height="30"}}
    <span class="boxel-drop-target__cta">
      Drag and drop from the card catalog
    </span>
  </div>
`, {
  strictMode: true,
  scope: () => ({
    cn,
    concat,
    svgJar
  })
}), templateOnly("index", "DropTarget"));
export default DropTarget;