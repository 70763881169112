import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import OrgSwitcherButton from './button';
import { concat, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import eq from 'ember-truth-helpers/helpers/eq';
import or from 'ember-truth-helpers/helpers/or';
import '@cardstack/boxel/styles/global.css';
import './index.css';
export default class BoxelOrgSwitcher extends Component {}
setComponentTemplate(precompileTemplate(`
    <ul class="boxel-org-switcher" ...attributes>
      {{#each @orgs as |org|}}
        <li>
          <OrgSwitcherButton
            @org={{org}}
            @isSelected={{eq @currentOrg org}}
            {{on "click" (fn @onChooseOrg org.id)}}
            aria-label={{concat (or org.title org.id) " organization page"}}
          />
        </li>
      {{/each}}
    </ul>
  `, {
  strictMode: true,
  scope: () => ({
    OrgSwitcherButton,
    eq,
    concat,
    or,
    on,
    fn
  })
}), BoxelOrgSwitcher);