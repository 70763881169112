var _class;

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

import templateOnly from "@ember/component/template-only";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import '@cardstack/boxel/styles/global.css';
import './index.css';
import cn from '@cardstack/boxel/helpers/cn'; //@ts-expect-error glint does not think this is consumed-but it is consumed in the template https://github.com/typed-ember/glint/issues/374

import { hash } from '@ember/helper';
import eq from 'ember-truth-helpers/helpers/eq';
import { Input, setComponentTemplate } from '@ember/component';
import { on } from '@ember/modifier';
import optional from 'ember-composable-helpers/helpers/optional';
import { action } from '@ember/object';
const Button = setComponentTemplate(precompileTemplate(`
  {{#let (eq @value @chosenValue) as |checked|}}
    <label
      class={{cn
        "boxel-toggle-button-group-option"
        boxel-toggle-button-group-option--checked=checked
        boxel-toggle-button-group-option--disabled=@disabled
      }}
      ...attributes
    >
      <Input
        name={{@name}}
        class={{cn
          "boxel-toggle-button-group-option__input"
          boxel-toggle-button-group-option__input--checked=checked
        }}
        @type="radio"
        @value={{@value}}
        disabled={{@disabled}}
        {{on "change" (optional @onChange)}}
      />
      <div>
        {{yield}}
      </div>
    </label>
  {{/let}}
`, {
  strictMode: true,
  scope: () => ({
    eq,
    cn,
    Input,
    on,
    optional
  })
}), templateOnly("index", "Button"));
let ToggleButtonGroupComponent = setComponentTemplate(precompileTemplate(`
    <fieldset class="boxel-toggle-button-group__fieldset" disabled={{@disabled}} ...attributes>
      <legend class="boxel-toggle-button-group__fieldset-legend">
        {{@groupDescription}}
      </legend>
      {{!-- this div is necessary because Chrome has a special case for fieldsets and it breaks grid auto placement --}}
      <div class="boxel-toggle-button-group__fieldset-container">
        {{yield
            (hash
              Button=(component
                Button
                kind="primary"
                disabled=@disabled
                name=@name
                onChange=this.changeValue
                chosenValue=@value
              )
            )
        }}
      </div>
    </fieldset>
  `, {
  strictMode: true,
  scope: () => ({
    hash,
    Button
  })
}), (_class = class ToggleButtonGroupComponent extends Component {
  changeValue(e) {
    let value = e.target.value;
    this.args.onChange?.(value);
  }

}, (_applyDecoratedDescriptor(_class.prototype, "changeValue", [action], Object.getOwnPropertyDescriptor(_class.prototype, "changeValue"), _class.prototype)), _class));
export { ToggleButtonGroupComponent as default };