var _class;

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { Link } from 'ember-link';
import eq from 'ember-truth-helpers/helpers/eq';
import cn from '@cardstack/boxel/helpers/cn';
import compact from 'ember-composable-helpers/helpers/compact';
import { on } from '@ember/modifier';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import { fn } from '@ember/helper';
import '@cardstack/boxel/styles/global.css';
import './index.css'; // This little component helps to make glint understand when we have a MenuItem and when we have a MenuDivider

class MenuItemRenderer extends Component {
  get asMenuItem() {
    return this.args.menuItem;
  }

}

setComponentTemplate(precompileTemplate(`
    {{#if (eq @menuItem.type "divider")}}
      {{yield to="divider"}}
    {{else}}
      {{yield this.asMenuItem to="item"}}
    {{/if}}
  `, {
  strictMode: true,
  scope: () => ({
    eq
  })
}), MenuItemRenderer);
let Menu = setComponentTemplate(precompileTemplate(`
    {{! template-lint-disable no-invalid-role }}
    <ul role="menu" class={{cn "boxel-menu" @class}} ...attributes>
      {{#if @items}}
        {{#each (compact @items) as |menuItem|}}
          <MenuItemRenderer @menuItem={{menuItem}}>
            <:divider>
              <hr class="boxel-menu__separator" data-test-boxel-menu-separator />
            </:divider>
            <:item as |menuItem|>
              <li
                role="none"
                class={{cn
                  "boxel-menu__item"
                  @itemClass
                  boxel-menu__item--dangerous=menuItem.dangerous
                  boxel-menu__item--has-icon=menuItem.icon
                  boxel-menu__item--selected=menuItem.selected
                }}
                data-test-boxel-menu-item
              >
                {{!-- template-lint-disable require-context-role --}}
                <a
                  role="menuitem"
                  href="#"
                  data-test-boxel-menu-item-text={{menuItem.text}}
                  tabindex={{menuItem.tabindex}}
                  {{on "click" (fn this.invokeMenuItemAction menuItem.action)}}
                >
                  {{#if menuItem.icon}}
                    {{svgJar
                      menuItem.icon
                      width="18"
                      height="18"
                      data-test-boxel-menu-item-icon=true
                    }}
                  {{/if}}
                  {{menuItem.text}}
                </a>
              </li>
            </:item>
          </MenuItemRenderer>
        {{/each}}
      {{/if}}
    </ul>
  `, {
  strictMode: true,
  scope: () => ({
    cn,
    compact,
    MenuItemRenderer,
    on,
    fn,
    svgJar
  })
}), (_class = class Menu extends Component {
  invokeMenuItemAction(actionOrLink, e) {
    e.preventDefault();
    let {
      closeMenu
    } = this.args;
    closeMenu && closeMenu();

    if (actionOrLink instanceof Link && actionOrLink.transitionTo) {
      actionOrLink.transitionTo();
    } else {
      actionOrLink();
    }
  }

}, (_applyDecoratedDescriptor(_class.prototype, "invokeMenuItemAction", [action], Object.getOwnPropertyDescriptor(_class.prototype, "invokeMenuItemAction"), _class.prototype)), _class));
export { Menu as default };